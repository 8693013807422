import { Routes, Route } from "react-router-dom";

import {
  ElementaryGrammar,
  WordOrder,
  SubjectPronouns,
  ObjectPronouns,
  PartsOfSpeech,
  ToBePresent,
  ToHave,
  PresentSimpleElementary,
  PossessiveAdjectivesElementary,
  AAnPluralsElementary,
  ThisTheseThatThoseElementary,
  AdjectivesElementary,
  ImperativesElementary,
  LetsElementary,
  QuestionsElementary,
  PossessiveSElementary,
  PrepositionsElementary,
  AdverbsOfFrequencyElementary,
  ExpressionsOfFrequencyElementary,
  CanElementary,
  PresentContinuousElementary,
  V2ingElementary,
} from "../pages";

export const ElementaryGrammarRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ElementaryGrammar title="A1 - A2 Grammar" />} />
      <Route path="wordOrder" element={<WordOrder title="Word Order" />} />
      <Route
        path="subjectPronouns"
        element={<SubjectPronouns title="Subject Pronouns" />}
      />
      <Route
        path="objectPronouns"
        element={<ObjectPronouns title="Object Pronouns" />}
      />
      <Route
        path="partsOfSpeech"
        element={<PartsOfSpeech title="Parts of Speech" />}
      />
      <Route
        path="toBePresent"
        element={<ToBePresent title="To Be (Present)" />}
      />
      <Route path="toHave" element={<ToHave title="To Have" />} />
      <Route
        path="presentSimple"
        element={<PresentSimpleElementary title="Present Simple" />}
      />
      <Route
        path="possessiveAdjectives"
        element={
          <PossessiveAdjectivesElementary title="Possessive Adjectives" />
        }
      />
      <Route
        path="aAnAndPlurals"
        element={<AAnPluralsElementary title="A / An, & Plurals" />}
      />
      <Route
        path="thisThesethatThose"
        element={
          <ThisTheseThatThoseElementary title="This / These / That / Those" />
        }
      />
      <Route
        path="adjectives"
        element={<AdjectivesElementary title="Adjectives" />}
      />
      <Route
        path="imperatives"
        element={<ImperativesElementary title="Imperatives" />}
      />
      <Route path="lets" element={<LetsElementary title="Let's" />} />
      <Route
        path="questions"
        element={<QuestionsElementary title="Questions" />}
      />
      <Route
        path="possessiveS"
        element={<PossessiveSElementary title="Possessive 's" />}
      />
      <Route
        path="prepositions"
        element={<PrepositionsElementary title="Prepositions" />}
      />
      <Route
        path="adverbsOfFrequency"
        element={<AdverbsOfFrequencyElementary title="Adverbs of Frequency" />}
      />
      <Route
        path="expressionsOfFrequency"
        element={
          <ExpressionsOfFrequencyElementary title="Expressions of Frequency" />
        }
      />
      <Route path="can" element={<CanElementary title="Can" />} />
      <Route
        path="presentContinuous"
        element={<PresentContinuousElementary title="Present Continuous" />}
      />
      <Route path="v2ing" element={<V2ingElementary title="V.1 + V.2-ing" />} />
    </Routes>
  );
};
